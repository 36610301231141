<template>
  <div class="test-plans font-inter pl-2  pr-2 pb-2">
    <Loader v-if="loaderState" />
    <template v-else>
      <PlanHeader
        :is-duplicate="PlanViewType === 'Duplicate'"
        :title="$t('plans.title')"
        :action-text="$t('plans.createTestPlan')"
        :active_label="$t('plans.active')"
        :archived_label="$t('plans.archived')"
        :btn_show="true"
        :filter="filter"
        :active-item-count="activeItemCount"
        :archived-item-count="archivedItemCount"
        @update-filter="updateFilter"
      />
      <div class="test-plans-wrapper">
        <placeholder v-if="items.length <= 0" />
        <v-card
          v-else
          class="py-6 px-6"
          rounded="lg"
          elevation="0"
          width="100%"
        >
          <div class="d-flex flex-row justify-start align-center">
            <v-responsive
              class="ma-0"
              max-width="344"
            >
              <v-text-field
                v-model="searchFilter"
                :loading="loading"
                class="rounded-lg pa-0"
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('searchByName')"
                dense
                filled
                hide-details
              />
            </v-responsive>
            <PlanFilter @applyFilters="applyFilters" />
            <v-menu
              left
              :close-on-content-click="false"
              :nudge-bottom="4"
              offset-y
              min-width="191"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="ml-auto pa-0 rounded-lg"
                  height="40"
                  min-width="40"
                  elevation="0"
                  v-on="on"
                >
                  <v-icon size="16px">
                    mdi-cog-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-list
                width="191"
                class="d-flex flex-column justify-space-between rounded-xl"
              >
                <v-list-item class="font-inter custom-text-14 custom-menu-item my-2">
                  {{ $t('columns') }}
                </v-list-item>
                <v-list-item
                  v-for="(header, index) in filteredMenuHeaders"
                  :key="index"
                  class="custom-menu-item"
                >
                  <v-checkbox
                    v-model="header.isSelected"
                    :value="header.isSelected"
                    :true-value="true"
                    :false-value="false"
                    off-icon="mdi-checkbox-blank"
                    class="ma-0 pa-0"
                    color="blue"
                    :size="16"
                    hide-details
                  >
                    <template v-slot:label>
                      <div class="custom-color-0c111d font-inter custom-text-14">
                        {{ header.text }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-list-item>
                <v-list-item
                  class="custom-menu-item my-2"
                  @click="handleColumnReset()"
                >
                  <div
                    class="custom-text-12 font-inter font-weight-semibold"
                    :class="{ 'blue--text font-weight-semibold ': isColumnFilter }"
                  >
                    {{ $t('restoreDefault') }}
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <test-plans-table
            :filtered-headers="filteredHeaders"
            :filtered-items="filteredPlans"
            :item-key="itemKey"
            :row-class="rowClass"
            :clear-selection="clearSelection"
            @edit-item="editItem"
            @select-row="handleRowClick"
            @select-item="setselected"
            @archive-item="confirmArchiveTestPlan"
            @unarchive-item="confirmUnArchiveTestPlan"
            @delete-item="confirmDeletePlan"
            @add-to-milestone="handleAddToMilestione"
          />
        </v-card>
        <div class="d-flex flex-row justify-end py-4">
          <v-menu
            v-if="selecteditems.length > 0"
            v-model="menuOpen"
            :close-on-content-click="false"
            offset-y
            top
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                dark
                depressed
                color="primary"
                class="font-inter text-capitalize font-weight-bold mr-4 mt-2"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                :disabled="!hasSelectedItems"
                v-on="on"
              >
                {{ $t('actions') }}
                <v-icon size="20">
                  {{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </v-btn>
            </template>
            <v-list class="actions-list font-inter text-left">
              <v-list-item
                v-if="filter === 'ongoing'"
                class="actions-item"
                @click="openAddToMilestoneDialog()"
              >
                <v-list-item-title class="d-flex align-center">
                  <AddToMilesonteIcon class="mr-1" />
                  {{ $t('plans.addToMilestone') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="filter === 'ongoing'"
                class="actions-item"
                @click="handleConfirmDialog('multi_archive')"
              >
                <v-list-item-title class="d-flex align-center">
                  <ArchiveIcon class="mr-1" />
                  {{ $t('plans.archive') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                class="actions-item"
                @click="handleConfirmDialog('multi_unarchive')"
              >
                <v-list-item-title class="d-flex align-center">
                  <UnarchiveIcon class="mr-1" />
                  {{ $t('plans.unarchive') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="actions-item"
                @click="handleConfirmDialog('multi_delete')"
              >
                <v-list-item-title class="error--text d-flex align-center">
                  <DeleteIcon class="mr-1" />
                  {{ $t('delete') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <plans-list-filter
        :open="isOpenFilter"
        @filters="applyFilters"
        @close="isOpenFilter = false"
      />
      <PlanAddToMilestone
        v-if="showAddToMilestoneDialog"
        :test-plan="selectedPlan"
        :selected-plans="selecteditems"
        :show-dialog="showAddToMilestoneDialog"
        @change-milestone-drawer-state="toggleMilestoneDrawer"
      />
    </template>
    <PlanDiscardDialog
      v-model="showConfirmDialog"
      :title="dialog.confirmDialogTitle"
      :content="dialog.confirmDialogContent"
      :content_part2="dialog.confirmDialogContentPartTwo"
      :run_name="dialog.confirmDialogName"
      :btn_label="dialog.confirmDialogBtnLabel"
      :color="dialog.confirmDialogBtnColor"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmBtnClick(dialog.confirmType)"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters, mapState ,mapActions } from 'vuex';
import DeleteIcon from '@/assets/svg/delete.svg';
import ArchiveIcon from '@/assets/svg/archived.svg';
import UnarchiveIcon from '@/assets/svg/unarchive-16x16.svg';
import AddToMilesonteIcon from '@/assets/svg/add-to-milestone.svg';
import Loader from '@/components/base/Loader.vue';
import Placeholder from '@/views/Tests/Plans/Components/Placeholder';
import PlansListFilter from '@/views/Tests/Plans/Components/List/PlansListFilter';
import makePlanService from '@/services/api/plan';
import { showErrorToast, showSuccessToast } from '@/utils/toast';
import TestPlansTable from '@/components/TestPlans/TestPlansTable.vue';
import { runStateMap } from '@/constants/grid';
import PlanDiscardDialog from '@/components/TestPlans/PlanDiscardDialog.vue';
import PlanFilter from '@/components/TestPlans/PlanFilter.vue';
import PlanHeader from '@/components/TestPlans/PlanHeader.vue';
import PlanAddToMilestone from '@/components/TestPlans/PlanAddToMilestone.vue';
import { sleep } from '@/utils/util';


export default {
  components: {
    PlanHeader,
    PlanFilter,
    PlanAddToMilestone,
    PlanDiscardDialog,
    TestPlansTable,
    Placeholder,
    PlansListFilter,
    DeleteIcon,
    ArchiveIcon,
    UnarchiveIcon,
    AddToMilesonteIcon,
    Loader,
  },
  props: ['PlanViewType', 'NewRunForm'],
  data() {
    return {
      selectedPlan: {},
      appliedFilters: null,
      loading: false,
      loaderState: false,
      tab: 'ongoing',
      isOpenFilter: false,
      headers: [],
      isColumnFilter: false,
      menuOpen: false,
      showConfirmBulkDeleteDialog: false,
      showConfirmBulkUnarchiveDialog: false,
      showConfirmBulkArchiveDialog: false,
      searchFilter: '',
      itemKey: 'uid',
      filter: 'ongoing',
      rowClass: () => 'test-plan-item',
      clearSelection: false,
      isFilter: false,
      showConfirmDialog: false,
      showAddToMilestoneDialog: false,
      dialog: {
        confirmDialogTitle: '',
        confirmDialogContent: '',
        confirmDialogContentPartTwo: '',
        confirmDialogBtnLabel: '',
        confirmDialogBtnColor: 'primary',
        confirmDialogName: '',
        confirmType: '',
      },
      selecteditems: []
    };
  },
  watch: {
    filteredPlans(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchTestPlans();
      }
    },
    selectedProject() {
      let handle = this.currentAccount.handle
      this.getProjectTestPlans(handle);
    },
    searchFilter: {
      handler() {
        this.loading = true;
        this.$nextTick(() => {
          this.loading = false;
        });
      },
      immediate: true
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState('plan', ['items']),
    ...mapState('user', ['currentAccount']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
      planHeaders:'headers/planTableHeaders'
    }),
    filteredPlans() {
      let filtered = this.testPlans;
      if (this.searchFilter) {
        filtered = filtered.filter((item) => this.matchesFilter(item));
      }
      if (this.isFilter && this.appliedFilters) {
        filtered = filtered.filter((item) => this.matchApplyFilter(item));
      }
      return filtered;
    },
    filteredHeaders() {
      this.updateHeaders({
        type: "plan",
        headers: this.headers
      });
      const filtered = this.headers.filter((header) => header.isSelected);
      if (filtered.length < 9)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered
    },
    filteredMenuHeaders() {
      return this.headers.filter((header) => header.value !== 'actions');
    },
    testPlans() {
      let filtered = this.items.filter((item) => ((item.archivedAt !== null) === (this.filter === 'ongoing' ? false : true)));
      return filtered.map((item) => {
        const createdFormat = new Date(item.createdAt);
        const createdAt = `${createdFormat.getFullYear()}-${String(createdFormat.getMonth() + 1).padStart(2, '0')}-${new String(createdFormat.getDate()).padStart(2, '0')}`
        return {
          ...item,
          progress: this.staticValues(item, runStateMap, 'New'),
          createdAt
        }
      });
    },
    activeItemCount() {
      return this.items.filter((item) => item?.archivedAt === null).length;
    },
    archivedItemCount() {
      return this.items.filter((item) => item?.archivedAt !== null).length;
    },
    seletedItemsCount() {
      return this.selecteditems.length;
    },
    hasSelectedItems() {
      return this.selecteditems.length > 0;
    },
  },
   created() {
    this.initializeHeaders('plan');
    this.headers = this.planHeaders;
    

  },
  methods: {
    // Actions from the 'testPlans' module
    ...mapActions( 'plan', 
    [
      'getAllTestPlans',
      'deleteTestPlan',
      'deleteTestPlans',
      'updateTestPlan',
      'updateTestPlans',]
    ),
     // header accions
    ...mapActions("headers",
    ['initializeHeaders',
    'updateHeaders',
    'resetHeaders']),
    toggleMilestoneDrawer(newState) {
      this.selectedPlan =
        this.showAddToMilestoneDialog = newState
    },
    async removePlan(handle, projectKey, uid) {
      const planService = makePlanService(this.$api);
      try {
        await planService.deleteTestPlan(handle, projectKey, uid);
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    async init() {
      this.loaderState = false;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
      }, 1000);

      try {
        let handle = this.$route.params.handle;
        await this.getProjectTestPlans(handle);
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'plans' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {

          await sleep(1000); 
          this.loaderState = false; 
        };
        hideLoader(); 
      }
    },
    updateFilter(newFilter) {
      this.filter = newFilter;
    },
    handleTab(value) {
      this.tab = value;
    },
    onCreateTestPlan() {
      this.$router.push({ name: 'TestPlanCreate' });
    },
    handleClickArchive() {
      this.showConfirmBulkArchiveDialog = true;
    },
    handleConfirmArchiveClick(status) {
      let plans = []
      this.selecteditems.map((item) => {
        let plan = {
          id: item.uid,
          archived: status
        };
        plans = [...plans, plan]
      });

      const successMessage = status
        ? this.$t('toast.archiveSuccess', { item: this.$t('plans.title') })
        : this.$t('toast.unarchiveSuccess', { item: this.$t('plans.title') });

      const errorMessage = status
        ? this.$t('toast.archiveError', { item: this.$t('plans.title') })
        : this.$t('toast.unarchiveError', { item: this.$t('plans.title') });

      this.updateTestPlans({
        swal: this.$swal,
        handle: this.currentAccount.handle,
        uid: this.selectedPlan.uid,
        projectKey: this.$route.params.key,
        payload: { plans },
      }).then(() => {
        showSuccessToast(this.$swal, successMessage);
        this.refreshData()
      }).catch(error => {
        showErrorToast(this.$swal, errorMessage);
      });
      this.handleCloseClick()
    },
    handleClickUnarchive() {
      this.showConfirmBulkUnarchiveDialog = true;
    },
    handleConfirmDeleteMultiClick() {
      let planIds = []
      this.selecteditems.map((item) => {
        planIds = [...planIds, item.uid]
      })
      let seletedItemsCount = this.seletedItemsCount;
      this.deleteTestPlans({
        swal: this.$swal,
        handle: this.currentAccount.handle,
        projectKey: this.$route.params.key,
        payload: { planIds: planIds },
      }).then(() => {
        showSuccessToast(this.$swal, this.$t("plans.deleteTestPlans", { count: seletedItemsCount }));
        this.refreshData()
      }).catch(error => {
        showErrorToast(this.$swal, this.$t("plans.deleteTestPlans", { count: seletedItemsCount }));
      });
      this.handleCloseClick()
    },
    async handleConfirmDeleteClick() {
      try {
        await this.removePlan(this.currentAccount.handle, this.$route.params.key, this.selectedPlan.uid);
        showSuccessToast(this.$swal, this.$t("plans.delete1TestPlan"));
        await this.refreshData();
      } catch (error) {
        showErrorToast(this.$swal, this.$t("plans.delete1TestPlan"));
      } finally {
        this.handleCloseClick();
      }
    },
    handleColumnReset() {
      this.resetHeaders('plan');
      this.headers = this.headers.map((header) => {
        header.isSelected = true;
        return header;
      })
    },
    applyFilters(filters) {
      if (filters) {
        this.appliedFilters = filters;
        this.isFilter = true;
      } else {
        this.isFilter = false;
      }
      this.refreshData()
    },
    handleAddToMilestione(item) {
      this.selectedPlan = item
      this.showAddToMilestoneDialog = true;
    },
    confirmDeletePlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('delete')
    },
    getProjectTestPlans(handle) {
      const key = this.$route.params.key;
      this.getAllTestPlans({ handle: handle, perPage: 99999, currentPage: 0, key });
    },
    async refreshData() {
      let handle = this.currentAccount.handle;
      this.getProjectTestPlans(handle);
    },
    async searchTestPlans() {
      if (this.searchFilter) {
        await this.$store.dispatch('plan/searchTestPlans', {
          handle: this.currentAccount.handle,
          projectKey: this.$route.params.key,
          text: this.searchFilter,
          perPage: this.perPage,
          currentPage: this.currentPage,
        });
      } else {
        await this.refreshData();
      }
    },
    editItem(item) {
      this.selectedPlan = item;
      this.$router.push({
        name: 'TestPlanDetail',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          planId: item.uid
        },
      });
    },
    handleRowClick(item) {
      this.$router.push({
        name: 'TestPlanDetail',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          planId: item.uid,
        },
      });
    },
    setselected(selecteditems) {
      this.clearSelection = false
      this.selecteditems = selecteditems
    },
    confirmArchiveTestPlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('archive')
    },
    confirmUnArchiveTestPlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('unarchive')
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter?.toLowerCase();
      return item?.name?.toLowerCase()?.includes(lowerCaseFilter)
    },
    staticValues(item, obj, defaultValue) {
      return obj[item.customFields?.state] || obj[defaultValue];
    },
    matchApplyFilter(item) {
      if (this.appliedFilters?.panel_configurations.length > 0 && !this.appliedFilters?.panel_configurations.includes(item.customFields.configurations))
        return false;
      if (this.appliedFilters?.panel_priority.length > 0 && !this.appliedFilters?.panel_priority.includes(item.priority))
        return false;
      if (this.appliedFilters?.panel_status.length > 0 && !this.appliedFilters.panel_status.includes(item.customFields.status))
        return false
      if (this.appliedFilters?.panel_milestone.length > 0 && !this.appliedFilters?.panel_milestone.find((milestone) => milestone.uid == item?.test_milestoneUid))
        return false
      if (this.appliedFilters?.panel_tag.length > 0 && !this.appliedFilters?.panel_tag.find((tag) => tag.name == item.customFields.tags))
        return false
      // if (this.appliedFilters?.testcases_range[0] > item.customFields.case_count || this.appliedFilters?.testcases_range[1] < item.customFields.case_count)
      //   return false
      // if (this.appliedFilters?.progressRange[0] > item.customFields.progress || this.appliedFilters?.progressRange[1] < item.customFields.progress)
      //   return false
      if (this.appliedFilters?.dateRange.start > item.createdAt || this.appliedFilters?.dateRange.end < item.createdAt)
        return false
      return true
    },
    handleConfirmBtnClick(type) {
      switch (type) {
        case 'multi_archive':
          this.handleConfirmArchiveClick(true);
          break;
        case 'multi_unarchive':
          this.handleConfirmArchiveClick(false);
          break;
        case 'multi_delete':
          this.handleConfirmDeleteMultiClick();
          break;
        case 'archive':
          this.handleConfirmOnArchiveClick(true);
          break;
        case 'unarchive':
          this.handleConfirmOnArchiveClick(false);
          break;
        case 'delete':
          this.handleConfirmDeleteClick();
          break;
      }
    },
    handleCloseClick() {
      this.showConfirmDialog = false;
      this.selectedPlan = [];
      this.selecteditems = [];
      this.clearSelection = true;
    },
    handleConfirmDialog(type) {
      this.showConfirmDialog = true;
      switch (type) {
        case 'multi_archive':
          this.dialog.confirmDialogTitle = this.$t('testruns.archive_dialog.title_multi');
          this.dialog.confirmDialogContent = this.$t('testruns.archive_dialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('testruns.archive_dialog.btn_label');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'multi_archive';
          break;
        case 'multi_unarchive':
          this.dialog.confirmDialogTitle = this.$t('testruns.unarchive_dialog.title_multi');
          this.dialog.confirmDialogContent = this.$t('testruns.unarchive_dialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('testruns.unarchive_dialog.btn_label');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'multi_unarchive';
          break;
        case 'multi_delete':
          this.dialog.confirmDialogTitle = this.$t('testruns.delete_dialog.title');
          this.dialog.confirmDialogContent = this.$t('testruns.delete_dialog.content');
          this.dialog.confirmDialogContentPartTwo = (this.filter === 'ongoing' ? this.$t('testruns.delete_dialog.content_part2') : '');
          this.dialog.confirmDialogBtnLabel = this.$t('testruns.delete_dialog.btn_label');
          this.dialog.confirmDialogBtnColor = 'danger';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'multi_delete';
          break;
        case 'archive':
          this.dialog.confirmDialogTitle = this.$t('plans.archiveDialog.title');
          this.dialog.confirmDialogContent = this.$t('plans.archiveDialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('plans.archiveDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = this.selectedPlan.name;
          this.dialog.confirmType = 'archive';
          break;
        case 'unarchive':
          this.dialog.confirmDialogTitle = this.$t('testruns.unarchive_dialog.title');
          this.dialog.confirmDialogContent = this.$t('testruns.unarchive_dialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('testruns.unarchive_dialog.btn_label');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = this.selectedPlan.name;
          this.dialog.confirmType = 'unarchive';
          break;
        case 'delete':
          this.dialog.confirmDialogTitle = this.$t('plans.deleteDialog.title');
          this.dialog.confirmDialogContent = this.$t('plans.deleteDialog.content');
          this.dialog.confirmDialogContentPartTwo = (this.filter === 'ongoing' ? this.$t('plans.deleteDialog.contentPart2') : '');
          this.dialog.confirmDialogBtnLabel = this.$t('plans.deleteDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'danger';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'delete';
          break;
      }
    },
    handleConfirmOnArchiveClick(status) {
      const payload = {
        archived: status,
      };
      const successMessage = status
        ? this.$t('toast.archiveSuccess', { item: this.$t('plans.title_single') })
        : this.$t('toast.unarchiveSuccess', { item: this.$t('plans.title_single') });

      const errorMessage = status
        ? this.$t('toast.archiveError', { item: this.$t('plans.title_single') })
        : this.$t('toast.unarchiveError', { item: this.$t('plans.title_single') });

      this.updateTestPlan({
        swal: this.$swal,
        handle: this.$route.params.handle,
        uid: this.selectedPlan.uid,
        projectKey: this.$route.params.key,
        payload: payload,
      }).then(() => {
        showSuccessToast(this.$swal, successMessage);
        this.refreshData()
      }).catch(error => {
        showErrorToast(this.$swal, errorMessage);
      });
      this.handleCloseClick()
    },
    openAddToMilestoneDialog() {
      this.showAddToMilestoneDialog = true;
    }
  },
};
</script>

<style lang="scss">
.test-plans {
  height: 100%;
  max-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
}

.test-plans-wrapper {
  height: 100%;
  max-height: calc(100% - 116px - 8px);
  background: #ffffff;
  border-radius: 8px;
}
</style>
